<template>
  <v-sheet
      class="wsRoundedLight "
      :style="`border : 1px solid ${wsBACKGROUND}`"
      :width="width || null"
  >

    <div>

      <div v-for="(item,i) in workItems" :key="i">

        <!-- Header-->
        <div  @click="!item.hideExpandArrows ? expandAction(item) : null" class="d-flex align-center justify-space-between py-4"
              :class="[{'pointer' : !item.noExpand}]"
        >
          <slot :name="'title.' + item.value ">
            <div class="d-flex align-center">
            <v-icon
                v-if="!item.noExpand && !item.hideExpandArrows"
                :class="[{'rotate' : item.expand} , {'pointer' : !item.noExpand}]"
                class="pointer ml-2"
                :color="wsACCENT"
            >
              mdi-menu-right
            </v-icon>
            <v-icon :class="item.noExpand ? 'ml-5' : null" v-if="item.icon" :color="wsATTENTION" class="mr-3">{{ item.icon }} </v-icon>
            <slot name="header" :item="item" :expand="expandAction">
              <h5 :style="`color : ${wsDARKER}`" style="font-weight: 600; font-size: 16px"> {{  item.name }} <br>
                <h5 :style="`color : ${wsACCENT}`" style="font-size: 16px" v-if="item.subtitle" class="font-weight-regular">{{  item.subtitle }}</h5>
              </h5>
            </slot>


          </div>
          </slot>
          <slot name="action" :item="item"  />
          <slot :name="'action.' + item.value " :forced-expand="() => forcedExpand(item)" :item="item" :expanded="item.expand" ></slot>
          <slot v-if="!SM" :name="'action.desktop.' + item.value " ></slot>


        </div>
        <slot v-if="SM" :name="'action.mobile.' + item.value " ></slot>
        <v-divider  :style="`border-color : ${wsBACKGROUND}`" />
        <!-- Content -->
        <v-expand-transition>


          <div v-if="item.expand" >

            <h5 v-if="item.text"
                @click="item.expand = !item.expand"
                class="pointer"
            >
              {{ item.text }}
            </h5>
            <slot name="item" :item="item" :element="item"/>
            <slot :name="`item.${item.value}`"  :item="item" :element="item"/>

            <v-divider  :style="`border-color : ${wsBACKGROUND}`" />
          </div>

        </v-expand-transition>

      </div>

    </div>

  </v-sheet>
</template>

<script>
export default {
  name: "wsAccordion",
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
    single : {
      type : Boolean,
      default : false
    },
    width : {}
  },
  watch: {
    items() {
      if ( !this.EQ(this.items, this.workItems) ) {

        this.items.forEach(item => {
          item.expand = !!item.expanded
        })

        this.workItems = JSON.parse(JSON.stringify(this.items))
      }
    }
  },
  data() {
    return {
      expand : false,
      workItems : []
    }
  },
  methods : {
    expandAction(selectedIcon) {
      if ( selectedIcon.noExpand) {
        return
      }
      selectedIcon.expand = !selectedIcon.expand ;
      this.$emit('select',selectedIcon.value)
      if ( this.single ) {
        this.workItems.forEach(item => {
          if ( item.value !== selectedIcon.value ) {
            item.expand = false
          }
        })
      }
    },
    forcedExpand(selectedIcon) {
      selectedIcon.expand = !selectedIcon.expand ;
      this.$emit('select',selectedIcon.value)
      if ( this.single ) {
        this.workItems.forEach(item => {
          if ( item.value !== selectedIcon.value ) {
            item.expand = false
          }
        })
      }
    }
  },
  mounted() {
    this.items.forEach(item => {
        item.expand = !!item.expanded
    })
    this.workItems = JSON.parse(JSON.stringify(this.items))
  }
 }
</script>

<style scoped>
.rotate {
  transform: rotate(90deg);
}
</style>